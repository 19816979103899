import * as _ from 'lodash'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  FIELDS,
  THANK_YOU_STEP_ROLE,
} from '../../../constants/roles'
import { getFieldPreset, getFormPreset } from '../preset/preset-service'
import { getFieldStyle, commonStyles } from './form-style-service'
import translations from '../../../utils/translations'
import { escapeRegExp, innerText } from '../../../utils/utils'
import { FormPreset, FormPresetName } from '../../../constants/form-types'
import { FieldPreset } from '../../../constants/field-types'
import {
  formComponent,
  FormSnapshot,
  ComponentStructure,
  RawComponentStructure,
  ComponentConfig,
  ControllerType,
  FormField,
} from '../../../constants/api-types'
import { FormPlugin } from '../../../constants/plugins'
import { createFieldWithMostCommonLayout } from './layout-service'
import { ResponsiveLayout, Layout } from '@wix/platform-editor-sdk'
import { getPrimaryConnectionFromStructure, isInputField } from '../utils'
import { createStackItemLayout, findNewFieldStackOrder } from '../layout-panel/utils'

const getComponentFromPreset = ravenInstance => async (
  { role, preset, locale },
  onFailedPresetCallback
) => {
  if (!preset) return
  const rawPreset = await fetchPreset(ravenInstance)(preset, locale, onFailedPresetCallback)
  if (!rawPreset) return
  const components = rawPreset.components
  const roleSchema = _recursiveFindComponentSchema(components, role)
  return roleSchema
}

const _recursiveFindComponentSchema = (components, role) => {
  for (let i = 0; i < components.length; i++) {
    const comp = components[i]
    if (comp.role == role) {
      return comp
    } else if (comp.components) {
      const subcomp = _recursiveFindComponentSchema(comp.components, role)
      if (subcomp) {
        return subcomp
      }
    }
  }
}

const _removeRawComponentByRole = (component, roles) => {
  if (_.has(component, 'components')) {
    _.set(
      component,
      'components',
      _.compact(
        _.map(component.components, comp => {
          comp = _removeRawComponentByRole(comp, roles)
          return roles.includes(comp.role) ? null : comp
        })
      )
    )
  }
  return component
}

const convertToInnerStructure = ({ role, connectionConfig, ...rest }: any) => ({
  role,
  connectionConfig,
  data: rest,
})

export const getExtraMessageText = ({ data, presetKey = '', newMessage }) => {
  const parsedMessage = innerText(data.text)
  return {
    text: data.text.replace(
      new RegExp(`>${escapeRegExp(innerText(data.text))}`),
      `>${newMessage ||
        (presetKey === FormPreset.REGISTRATION_FORM
          ? translations.t('settings.errorMessage.registrationForm')
          : parsedMessage)}`
    ),
  }
}

const getChildComponents = (presetKey, comp, newMessage?) =>
  comp.components &&
  comp.components.map(childComp =>
    deConstructComponent({ presetKey, rawComp: childComp, newMessage })
  )

const deConstructComponent = ({ presetKey, rawComp, newMessage = null }: formComponent) => {
  const comp = rawComp
  comp.connectionConfig = _.merge({}, comp.config, comp.connectionConfig)
  if (comp.role === ROLE_MESSAGE || comp.role === ROLE_DOWNLOAD_MESSAGE) {
    comp.data = _.merge(
      {},
      comp.data,
      getExtraMessageText({ data: comp.data, presetKey, newMessage })
    )
  }
  comp.components = getChildComponents(presetKey, comp, newMessage)
  return comp
}

export const fetchPreset = ravenInstance => async (
  presetKey: FormPresetName,
  locale: string = 'en',
  onFailedPresetCallback: Function = _.noop
): Promise<RawComponentStructure | undefined> => {
  let rawPreset
  try {
    rawPreset = await getFormPreset(ravenInstance)(presetKey, locale)
  } catch (e) {
    await onFailedPresetCallback(`${presetKey}: ${(<Error>e).message}`)
    return
  }
  return rawPreset
}

// return { role, connectionConfig, data }
export const createField = ({
  preset,
  fieldType,
  extraData,
  commonStyles,
  fieldsData,
  formWidth,
  isResponsive,
  layout,
  plugins,
}: {
  preset: string
  fieldType: FieldPreset
  extraData: any
  commonStyles: commonStyles
  fieldsData: FormField[] | undefined
  formWidth?: number
  isResponsive?: boolean
  layout?: any | ResponsiveLayout
  plugins: FormPlugin[]
}) => {
  // TODO remove presetKey
  const stackOrder = isResponsive ? findNewFieldStackOrder(fieldsData) : 0

  const rawPreset = getFieldPreset({ fieldType, extraData, plugins, isResponsive, stackOrder })
  
  let fieldComponent

  if (isResponsive) {
    fieldComponent = _.merge({}, deConstructComponent({ presetKey: preset, rawComp: rawPreset }), {
      layoutResponsive: { ...layout },
    })
  } else {
    const width = Math.min(formWidth - layout.x, layout.width || rawPreset.layout.width)
    fieldComponent = _.merge({}, deConstructComponent({ presetKey: preset, rawComp: rawPreset }), {
      layout: { ...layout, width },
    })
  }

  const fieldStyle = getFieldStyle(commonStyles, fieldType)
  if (isResponsive) {
    _.assign(fieldComponent.style.stylesInBreakpoints[0].style.properties, fieldStyle)
  } else {
    _.assign(fieldComponent.style.style.properties, fieldStyle)
  }

  return fieldsData
    ? convertToInnerStructure(
        createFieldWithMostCommonLayout(fieldType, fieldsData, fieldComponent, isResponsive)
      )
    : convertToInnerStructure(fieldComponent)
}

const restoreFieldSchema = ravenInstance => async (
  { role, preset, locale, fallbackSchema },
  onFailedCallback
): Promise<{ rawSchema; fallback }> => {
  const rawSchema = await getComponentFromPreset(ravenInstance)(
    {
      role: role,
      preset,
      locale,
    },
    onFailedCallback
  )

  if (rawSchema) {
    return { rawSchema, fallback: false }
  } else {
    return { rawSchema: fallbackSchema, fallback: true }
  }
}

export const fetchThankYouStepSchema = ravenInstance => async ({ preset, locale }) => {
  const { rawSchema } = await restoreFieldSchema(ravenInstance)(
    { role: THANK_YOU_STEP_ROLE, preset, locale, fallbackSchema: {} },
    reason => this.coreApi.logFetchPresetsFailed(null, reason)
  )
  _removeRawComponentByRole(rawSchema, [ROLE_MESSAGE, ROLE_DOWNLOAD_MESSAGE])
  return rawSchema
}

export const fetchSubmitButtonSchema = ravenInstance => async (
  { label, preset, locale, fallbackSchema, orderInStack = null },
  onFailedCallback
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    { role: ROLE_SUBMIT_BUTTON, preset, locale, fallbackSchema },
    onFailedCallback
  )

  let extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  if (orderInStack) {
    extraData = _.merge({}, extraData, { layoutResponsive: createStackItemLayout(orderInStack) })
  }

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchMultiStepNavigationButtonSchema = ravenInstance => async (
  { label, preset, locale, fallbackSchema, role },
  onFailedCallback
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    { role, preset, locale, fallbackSchema },
    onFailedCallback
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchLoginLinkSchema = ravenInstance => async (
  { label, preset, locale, fallbackSchema },
  onFailedCallback
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      preset,
      locale,
      fallbackSchema,
    },
    onFailedCallback
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const loginLinkComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(loginLinkComponent)
}

export const fetchHiddenMessage = ravenInstance => async (
  {
    newMessage,
    formLayout,
    preset,
    locale,
    fallbackSchema,
    role = ROLE_SUBMIT_BUTTON,
    orderInStack,
  }: {
    newMessage?
    formLayout
    preset
    locale
    fallbackSchema
    role
    orderInStack?
  },
  onFailedCallback
) => {
  const { rawSchema } = await restoreFieldSchema(ravenInstance)(
    { role, preset, locale, fallbackSchema },
    onFailedCallback
  )

  const isCenterAligned =
    rawSchema.data.text.match(/text-align:[\s]*center/) && rawSchema.layout.x === 0
  const messageWidth = isCenterAligned ? formLayout.width : rawSchema.layout.width

  rawSchema.role = role

  const layout: { layout: any } | { layoutResponsive: ResponsiveLayout } = orderInStack
    ? { layoutResponsive: createStackItemLayout(orderInStack) }
    : { layout: { width: messageWidth } }
  const hiddenMessageComponent = _.merge(
    {},
    deConstructComponent({
      rawComp: rawSchema,
      newMessage,
    }),
    layout
  )

  return convertToInnerStructure(hiddenMessageComponent)
}

const createWidgetResponsiveLayout = (): ResponsiveLayout => ({
  id: '',
  type: 'LayoutData',
  itemLayouts: [
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      margins: {
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
      },
      alignSelf: 'start',
      justifySelf: 'center',
      breakpoint: undefined,
    },
  ],
  componentLayouts: [
    {
      type: 'ComponentLayout',
      height: {
        type: 'auto',
      },
      width: {
        type: 'percentage',
        value: 30,
      },
      minHeight: {
        type: 'px',
        value: 0,
      },
      minWidth: {
        type: 'px',
        value: 200,
      },
      hidden: false,
      breakpoint: undefined,
    },
  ],
  containerLayouts: [
    {
      type: 'GridContainerLayout',
      rows: [
        {
          value: 1,
          type: 'fr',
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      breakpoint: undefined,
    },
  ],
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
    pageId: 'c1dmp',
  },
})

export const convertPreset = (
  structure: RawComponentStructure,
  { controllerId, coords = {}, appWidgetStructre = null, width = null,  space = null }
): ComponentStructure => {
  const formAfterWidthChange = width ? changeFormWidth(structure, width,  space) : structure
  const presetLayout = _.merge({}, formAfterWidthChange.layout, coords)
  const rootComponent = connectComponents(
    {
      ...formAfterWidthChange,
      layout: appWidgetStructre ? _.merge({}, presetLayout, { x: 0, y: 0 }) : presetLayout,
    },
    controllerId
  )

  return appWidgetStructre
    ? {
        ...appWidgetStructre,
        data: { ...appWidgetStructre.data, id: controllerId },
        layout: presetLayout,
        ...(structure.layoutResponsive ? { layoutResponsive: createWidgetResponsiveLayout() } : {}),
        style: 'appWidget1',
        components: [rootComponent],
      }
    : rootComponent
}

const connectComponents = (componentStructure: RawComponentStructure, controllerId: string) => {
  const convertedComponent = connectComponent(componentStructure, controllerId)

  if (!convertedComponent.components) {
    return convertedComponent
  }

  return {
    ...convertedComponent,
    components: convertedComponent.components.map(c => connectComponents(c, controllerId)),
  }
}

export const connectComponent = (
  componentStructure: RawComponentStructure,
  controllerId: string
) => ({
  ..._.omit(componentStructure, ['role', 'config']),
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role: componentStructure.role,
        isPrimary: true,
        config: JSON.stringify(componentStructure.config),
        controllerId,
      },
    ],
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
  },
})

export const enhanceConfigByRole = async (
  structure: RawComponentStructure,
  produceConfigMap: { [key: string]: (config) => Promise<ComponentConfig> }
): Promise<RawComponentStructure> => {
  const scanStructure = async componentStructure => {
    const produceConfig = produceConfigMap[componentStructure.role] || _.identity
    return {
      ...componentStructure,
      config: await produceConfig(componentStructure.config),
      ...(componentStructure.components && {
        components: await Promise.all(
          componentStructure.components.map(component =>
            enhanceConfigByRole(component, produceConfigMap)
          )
        ),
      }),
    }
  }

  return scanStructure(structure)
}

export const enhanceStructreWithSnapshot = (structure, formSnapshot: FormSnapshot) => {
  const formStructure = _.merge({}, structure, {
    config: formSnapshot.formComponent.config,
    layout: formSnapshot.formComponent.layout,
  })
  formStructure.components = formSnapshot.components.map(component =>
    _.omit(component, 'componentRef')
  )
  return formStructure
}

export const getFormControllerType = (structure: RawComponentStructure): ControllerType => {
  const plugins = structure.config.plugins
  return _.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })
    ? ControllerType.MULTI_STEP_FORM
    : _.find(plugins, { id: FormPlugin.GET_SUBSCRIBERS })
    ? ControllerType.GET_SUBSCRIBERS
    : ControllerType.WIX_FORMS
}

export const getComponentByRole = (
  structure: ComponentStructure,
  role: string
): ComponentStructure | undefined => {
  const primaryConnection = getPrimaryConnectionFromStructure(structure)
  if (primaryConnection && primaryConnection.role === role) {
    return structure
  }
  if (!structure.components) {
    return
  }
  return _.first(
    structure.components.map(component => getComponentByRole(component, role)).filter(c => c)
  )
}

export const connectComponentToConnection = (
  component: ComponentStructure,
  { role, config, controllerId }
): ComponentStructure => ({
  ...component,
  connections: {
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role,
        config: JSON.stringify(config),
        isPrimary: true,
        controllerId,
      },
    ],
  },
})

export const limitComponentInContainer = (component: ComponentStructure, containerHeight: number) =>
  component &&
  _.merge({}, component, {
    layout: {
      y: Math.max(Math.min(component.layout.y, containerHeight - component.layout.height - 10), 0),
    },
  })

type LayoutUpdate = { layout: Partial<Layout>; id: number }
const calcFormWidthDesktop = ({
  inputFields,
  submitButton,
  successMessage,
  form,
   space,
  width,
}: {
  inputFields: LayoutUpdate[]
  submitButton: LayoutUpdate
  successMessage: LayoutUpdate
  form: LayoutUpdate
   space: number
  width: number
}) => {
  const fieldsUpdates: LayoutUpdate[] = []
  const isFieldsSharingTheRow = (field: LayoutUpdate, index: number) =>
    index > 0 && field.layout.y === inputFields[index - 1].layout.y
  const rows = inputFields.reduce<LayoutUpdate[][]>((acc, curr, index) => {
    if (isFieldsSharingTheRow(curr, index)) {
      acc[acc.length - 1] = acc[acc.length - 1].concat(curr)
    } else {
      acc = acc.concat([[curr]])
    }
    return acc
  }, [])

  const updateFormKeepColumns = (rowsToReduce: LayoutUpdate[][]) =>
    rowsToReduce.reduce((prevY, row) => {
      const fieldsInRow = row.length
      const elementWidth = (width -  space * (fieldsInRow - 1)) / fieldsInRow

      const firstElement = row[0]
      const newY = prevY >= 0 ? prevY +  space : firstElement.layout.y
      row.reduce((prevX, field) => {
        const newX = prevX >= 0 ? prevX +  space + elementWidth : field.layout.x
        fieldsUpdates.push({
          id: field.id,
          layout: { width: elementWidth, x: newX, y: newY },
        })
        return newX
      }, -1)

      return newY + (firstElement.layout.height || 0)
    }, -1)

  const lastY = updateFormKeepColumns(rows)
  const lastElement = rows[rows.length - 1][0]
  const heightDiff = lastElement.layout.y + lastElement.layout.height - lastY
  const buttonNewY = submitButton.layout.y - heightDiff

  if (submitButton.layout.width === form.layout.width) {
    fieldsUpdates.push({
      id: submitButton.id,
      layout: { width, y: buttonNewY, x: lastElement.layout.x },
    })
  } else {
    const calcXDecrease = (elmX: number, elmWidth: number) => {
      const widthDiff = form.layout.width - width
      const leftSpace = elmX - lastElement.layout.x
      const sidesRadio = leftSpace / (form.layout.width - elmWidth)
      return widthDiff * sidesRadio
    }
    fieldsUpdates.push({
      id: submitButton.id,
      layout: {
        x: submitButton.layout.x - calcXDecrease(submitButton.layout.x, submitButton.layout.width),
        y: buttonNewY,
      },
    })
  }

  const messageNewY = successMessage.layout.y - heightDiff
  const messageNewWidth = width * (successMessage.layout.width / form.layout.width)
  const messageNewX = successMessage.layout.x

  fieldsUpdates.push({
    id: successMessage.id,
    layout: { width: messageNewWidth, y: messageNewY, x: messageNewX },
  })

  const lastFieldY = fieldsUpdates[fieldsUpdates.length - 1].layout.y || 0
  const height = successMessage.layout.height + lastFieldY

  const formUpdate = {
    id: form.id,
    layout: { width, height },
  }

  return [formUpdate, ...fieldsUpdates]
}

// this function will work only with naive form structure
const changeFormWidth = (
  formStructure: RawComponentStructure,
  width: number,
   space: number
): RawComponentStructure => {
  const toLayoutUpdate = (component: RawComponentStructure, index: number): LayoutUpdate => ({
    layout: component.layout,
    id: index,
  })

  const submitButtonIdx = _.findIndex(formStructure.components, { role: ROLE_SUBMIT_BUTTON })
  const thankYouMessageIdx = _.findIndex(formStructure.components, { role: ROLE_MESSAGE })
  const inputFields = formStructure.components
    .filter(component => isInputField(component.role))
    .map(toLayoutUpdate)

  const [formUpdate, ...componentsUpdated] = calcFormWidthDesktop({
    inputFields: _.sortBy(inputFields, [c => c.layout.y, c => c.layout.x]),
    submitButton: toLayoutUpdate(formStructure.components[submitButtonIdx], submitButtonIdx),
    successMessage: toLayoutUpdate(
      formStructure.components[thankYouMessageIdx],
      thankYouMessageIdx
    ),
    form: toLayoutUpdate(formStructure, -1),
    width,
     space,
  })

  const components = formStructure.components.map((comp, idx) => ({
    ...comp,
    layout: { ...comp.layout, ..._.find(componentsUpdated, { id: idx }).layout },
  }))

  return {
    ...formStructure,
    layout: { ...formStructure.layout, ...formUpdate.layout },
    components,
  }
}
