import { ComponentConnection } from '../../../constants/api-types'
import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import { ContactSyncProps } from '../../../panels/contact-sync-panel/components/contact-sync-panel'
import { SyncField, SyncData } from '../../../panels/contact-sync-panel/constants/types'
import { getActivePluginFromComponentConnection } from '../plugins/utils'
import { isNewCustomFieldId, areSyncDataEqual } from '../../../panels/contact-sync-panel/utils'
import {
  getSyncedFieldsCount,
  createSyncFieldsFromFormFields,
} from './utils'
import RemoteApi from '../../../panels/commons/remote-api'
import * as _ from 'lodash'
import { CustomFieldResponse, customTypes } from '../../../constants/field-types'
import { CUSTOM_FIELD } from '../../../constants/crm-types-tags'

export default class ContactSyncApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  private experiments: Experiments

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, ravenInstance, experiments }
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.ravenInstance = ravenInstance
    this.experiments = experiments
  }

  public async loadInitialPanelData({
    formComponentRef,
    componentConnection,
  }: {
    formComponentRef: ComponentRef
    componentConnection: ComponentConnection
  }): Promise<Partial<ContactSyncProps>> {
    const plugin = getActivePluginFromComponentConnection(componentConnection)

    return Promise.all([
      this.coreApi.fields.getFieldsSortByXY(formComponentRef),
      this.remoteApi.getCustomFields(),
    ]).then(([fieldsOnStage, customFields]) => ({
      loading: false,
      fields: createSyncFieldsFromFormFields(fieldsOnStage, plugin, customFields),
      customFields,
      preset: _.get(componentConnection, 'config.preset'),
    }))
  }

  private async _createCustomField(
    customFieldName: string,
    customFieldType: customTypes
  ): Promise<string> {
    const { id } = await this.remoteApi.createCustomField({
      name: customFieldName,
      fieldType: customFieldType,
    })

    return id
  }

  private _saveCustomField(customField: CustomFieldResponse): Promise<void> {
    return this.remoteApi.updateCustomFieldName({ id: customField.id, newName: customField.name })
  }

  public async createCustomField(
    fieldComponentRef: ComponentRef,
    customFieldName: string,
    customFieldType: customTypes
  ): Promise<SyncData> {
    const id = await this._createCustomField(customFieldName, customFieldType)
    const updatedSync = {
      crmType: CUSTOM_FIELD,
      crmTag: undefined,
      customFieldId: id,
      customFieldName: customFieldName,
    }
    await this.saveCurrentSyncData(fieldComponentRef, updatedSync)
    return updatedSync
  }

  public async saveCustomField(
    fieldComponentRef: ComponentRef,
    customFieldToUpdate: CustomFieldResponse
  ): Promise<SyncData> {
    await this._saveCustomField(customFieldToUpdate)
    const updatedSync = {
      crmType: CUSTOM_FIELD,
      crmTag: undefined,
      customFieldId: customFieldToUpdate.id,
      customFieldName: customFieldToUpdate.name,
    }
    await this.saveCurrentSyncData(fieldComponentRef, updatedSync)
    return updatedSync
  }

  public saveCurrentSyncData(fieldRef: ComponentRef, data: SyncData): Promise<void> {
    return this.coreApi.setComponentConnection(fieldRef, data, false)
  }

  public async saveContactSyncFieldsAndReturnSyncedFieldsCount(
    fields: SyncField[],
    initialFields: SyncField[],
    customFields: CustomFieldResponse[],
    initialCustomFields: CustomFieldResponse[]
  ): Promise<{
    syncedFieldsCount: number
  }> {
    const initialCustomFieldsById = _.keyBy(initialCustomFields, 'id')
    const initialFieldsById = _.keyBy(initialFields, 'componentRef.id')

    const changedFields = fields.filter(
      field => !areSyncDataEqual(field.syncData, initialFieldsById[field.componentRef.id].syncData)
    )
    const fieldsWithNewCustomField = changedFields.filter(field =>
      isNewCustomFieldId(field.syncData.customFieldId)
    )
    const fieldsWithChangedSync = changedFields.filter(
      field => !isNewCustomFieldId(field.syncData.customFieldId)
    )

    const customFieldsToUpdate = customFields
      .filter(customField => !isNewCustomFieldId(customField.id))
      .filter(
        ({ name, id }) =>
          initialCustomFieldsById[id].name !== name &&
          _.some(fieldsWithChangedSync, field => field.syncData.customFieldId === id)
      )

    const fieldsWithCreatedNewCustomField = await Promise.all(
      fieldsWithNewCustomField.map<Promise<{ componentRef: ComponentRef; syncData: SyncData }>>(
        async field => {
          const customFieldId = await this._createCustomField(
            field.syncData.customFieldName,
            field.customFields[0]
          )
          return {
            componentRef: field.componentRef,
            syncData: { ...field.syncData, customFieldId },
          }
        }
      )
    )

    await Promise.all(customFieldsToUpdate.map(this._saveCustomField.bind(this)))

    const fieldsToUpdateConfig = _.flatten([fieldsWithCreatedNewCustomField, fieldsWithChangedSync])

    await Promise.all(
      fieldsToUpdateConfig.map(field =>
        this.saveCurrentSyncData(field.componentRef, field.syncData)
      )
    )

    return {
      syncedFieldsCount: getSyncedFieldsCount(
        fields.map(({ syncData: { crmType, customFieldId } }) => ({
          crmType,
          customFieldId,
        }))
      ),
    }
  }
}
